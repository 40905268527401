import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeSquare,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';

const OrderReviewFooterComponent = () => {
  return (
    <div className="text-left">
      <p className="mt-3">Need to speak with us right away? Here's how:</p>
      <p>Customer Care Team</p>
      <div className="py-1">
        <FontAwesomeIcon icon={faEnvelopeSquare} size="1x" />
        <span className="pl-2">customercare@central-refinishing.com</span>
      </div>
      <div className="py-1">
        <FontAwesomeIcon icon={faPhoneAlt} size="1x" />
        <span className="pl-2">(609) 496-9436</span>
      </div>
    </div>
  );
};

export default OrderReviewFooterComponent;
