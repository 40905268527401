import axios from 'axios';
import { config } from '../constant';

class UserService {
  constructor() {
    if (!!UserService.instance) {
      return UserService.instance;
    }

    UserService.instance = this;
    this.authenticated = false || localStorage.getItem('auth_token');
    return this;
  }

  login(body) {
    return axios
      .post(`${config.urls.API_URL}Login`, {
        UserId: body.email,
        Password: body.password,
      })
      .then((res) => {
        if (res.data && res.data.Success) {
          this.authenticated = true;
        }
        return res.data;
      })
      .catch((err) => err);
  }

  proxyLogin(body) {
    return axios
      .post(`${config.urls.API_URL}api/login`, {
        email: body.email,
        password: body.password,
      })
      .then((res) => {
        if (res.data && res.data.Success) {
          localStorage.setItem('email', body.email);
          localStorage.setItem('password', body.password);
          this.authenticated = true;
        }
        return res;
      });
  }

  logOut() {
    return axios
      .post(`${config.urls.API_URL}Logout`, {
        SessionKey: localStorage.getItem('auth_token'),
      })
      .then((res) => {
        if (res.data.Success || res.data.Error.Code === 10401) {
          localStorage.removeItem('auth_token');
          localStorage.removeItem('api_token');
          localStorage.removeItem('email');
          localStorage.removeItem('password');
          this.authenticated = false;
        }
        return res.data;
      });
  }

  getCurrentUser() {
    return axios
      .get(
        `${config.urls.API_URL}Users/Current?sessionKey=${localStorage.getItem(
          'auth_token'
        )}`
      )
      .then((res) => {
        return res.data;
      });
  }

  isAuthenticated() {
    return this.authenticated;
  }

  resetPassword(body) {
    return axios.post(`${config.urls.API_URL}api/password/reset-link`, {
      email: body.email,
    });
  }

  requestNewPassword(body) {
    return axios.post(`${config.urls.API_URL}api/password/request-new`, {
      token: body.token,
      password: body.password,
    });
  }
}

export default new UserService();
