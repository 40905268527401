import React from 'react';

const LoginContainerComponent = (props) => {
  return (
    <div className="container login-container">
      <div className="row">
        <div className="content">
          <div className="col-xs-12 col-lg-6 login-form-1">
            <img src="./newlogo.png" className="container-logo" />
            <h3>{props.title}</h3>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginContainerComponent;
