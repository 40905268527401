import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import ErrorBoundary from './components/error-class.component'
import { ErrorBoundary } from 'react-error-boundary';
import OurFallbackComponent from './components/fallback.component';
import * as serviceWorker from './serviceWorker';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
const errorhandler = (error, errorInfo) => {
  console.log('from index.js=', error, '===', errorInfo);
};
ReactDOM.render(
  <ErrorBoundary
    FallbackComponent={OurFallbackComponent}
    onError={errorhandler}
  >
    <App />
  </ErrorBoundary>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
