import axios from 'axios';

import { config } from '../constant';
class UnsubscribeService {
  constructor() {
    this.url = `${config.urls.API_URL}api/unsubscribe/`;
  }

  Unsubscribe(token) {
    return axios.post(`${this.url}?token=${token}`);
  }
}

export default new UnsubscribeService();
