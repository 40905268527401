import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginContainerComponent from '../components/login-container.component';
import userService from '../services/user.service';

const ResetPasswordPage = withRouter(({ history }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = (data) => {
    setIsLoading(true);
    userService
      .resetPassword(data)
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message, {
          onClose: (e) => history.push('/'),
          autoClose: 6000,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Error, { autoClose: 6000 });
      });
  };
  return (
    <div>
      <LoginContainerComponent title={'Reset Password'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Email"
              name="email"
              ref={register({ required: true })}
            />
            {errors.email && <small>This field is required</small>}
          </div>
          <div className="form-group mt-4 mb-0">
            <button
              type="submit"
              className="submit-btn bg-blue border-radius w-50"
            >
              Reset
            </button>
          </div>
          {isLoading ? (
            <div className="spinner-border text-dark" role="status"></div>
          ) : null}
        </form>
      </LoginContainerComponent>
      <ToastContainer />
    </div>
  );
});

export default ResetPasswordPage;
