import React, { useState } from 'react';
import useForm from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';

import { getQueryStringValue } from '../components/query-params.component';
import LoginContainerComponent from '../components/login-container.component';
import userService from '../services/user.service';

const NewPasswordPage = withRouter(({ history }) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const token = getQueryStringValue('token' || '');

  // send password change request
  const onSubmit = ({ password, confirmPassword }) => {
    if (password === confirmPassword) {
      setIsLoading(true);
      setPasswordMatchError(false);
      userService
        .requestNewPassword({ token, password })
        .then((res) => {
          setIsLoading(false);
          toast.success(res.data.message, {
            onClose: (e) => history.push('/'),
            autoClose: 6000,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error('Invalid request', 6000);
        });
    } else {
      setPasswordMatchError(true);
    }
  };
  return (
    <div>
      <LoginContainerComponent title={'New Password'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Enter your password"
              name="password"
              ref={register({ required: true })}
            />
            {errors.password && <span>This field is required</span>}
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Confirm your password"
              name="confirmPassword"
              ref={register({ required: true })}
            />
            {errors.confirmPassword && <span>This field is required</span>}
          </div>
          <div className="form-group mt-4">
            <button
              type="submit"
              className="submit-btn bg-blue border-radius w-50"
            >
              Submit
            </button>
          </div>

          {passwordMatchError ? (
            <span className="">Password does not match</span>
          ) : null}
          {isLoading ? (
            <div className="spinner-border text-dark" role="status"></div>
          ) : null}
        </form>
      </LoginContainerComponent>
      <ToastContainer />
    </div>
  );
});

export default NewPasswordPage;
