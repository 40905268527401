import axios from 'axios';

import { config } from '../constant';
import { instance } from './interceptor.service';
class ReviewService {
  constructor() {
    this.url = `${config.urls.API_URL}api/review/`;
  }

  getAll(date) {
    return instance.get(`${this.url}?date=${date}`);
  }
  // update review
  postReivew({ review, comment, token, email }) {
    return axios.post(this.url, {
      review,
      comment,
      token,
      email,
    });
  }

  postReviews({ orderReviews, comment, token, email }) {
    return axios.post(`${this.url}post-many`, {
      orderReviews,
      comment,
      token,
      email,
    });
  }

  getReviewById(id) {
    return axios.get(`${this.url}get-by-id/${id}`);
  }

  getReviewByIdGroupByCustomerId(id) {
    return axios.get(`${this.url}get-by-id-group-by/${id}`);
  }

  checkReview(token) {
    return axios.get(`${this.url}check?token=${token}`);
  }
}

export default new ReviewService();
