const api = {
  urls: {
    API_URL: process.env.REACT_APP_SERVER_URL,
  },
};

export const config = api;

export const FUEL_SURCHARGE_SKU = 6001548983;
export const FUEL_SURCHARGE_PERCENT = 1.5;
