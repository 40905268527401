import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
const fallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
  return (
    <div className="row justify-content-center mt-3">
      <div className="col-12 col-lg-8 text-center py-5">
        <div className="mx-auto">
          <Alert variant="danger">
            <p>Something went wrong:</p>
            {/* <pre>{error}</pre>
      <pre>{componentStack}</pre> */}
            <pre>{error.toString()}</pre>
            <pre>{componentStack ? componentStack.toString() : ''}</pre>
            <div className="mb-0">
              <Button variant="danger" onClick={resetErrorBoundary}>
                Try again
              </Button>
            </div>
          </Alert>
        </div>
      </div>
    </div>
  );
};

export default fallbackComponent;
