import React from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faFrown } from '@fortawesome/free-solid-svg-icons';

const OrderReviewTableComponent = (props) => {
  const changeReview = (workOrderId) => {
    if (workOrderId === props.selectedReview) {
      props.setSelectedReview(false);
    } else {
      props.setSelectedReview(workOrderId);
    }
  };

  return (
    <div className="my-3 review-border py-2">
      <div className="text-left ml-2">
        <p className="font-weight-bold d-inline">Customer Name: </p>
        <p className="d-inline">{props.orderReview[0].customerName}</p>
      </div>
      <div className="text-left ml-2">
        <p className="font-weight-bold d-inline">Date: </p>
        <p className="d-inline">
          {moment(props.orderReview[0].orderDate).format('MM/DD/YY')}
        </p>
      </div>
      <Table striped bordered hover className="my-4">
        <thead>
          <tr>
            <th>Work Order #</th>
            <th>Products/Services</th>
            <th>Team</th>
            <th>Unit Number</th>
            <th>Review</th>
            <th>Change Review</th>
          </tr>
        </thead>
        <tbody>
          {props.orderReview
            ? props.orderReview.map((workOrder, index) => (
                <tr key={workOrder._id}>
                  <td>{workOrder.workOrderNumber}</td>
                  {
                    <td className="w-25">
                      {workOrder.workOrderLines
                        .filter(
                          (workOrderLine) =>
                            workOrderLine.Inventory.Name !== 'Unit Number'
                        )
                        .map((workOrderLine) => workOrderLine.Inventory.Name)
                        .join(',')}
                    </td>
                  }
                  <td>{workOrder.teamName}</td>
                  <td>{workOrder.unitNumber}</td>
                  <td>
                    {props.selectedReview &&
                    workOrder._id === props.selectedReview ? (
                      <div>
                        <FontAwesomeIcon
                          onClick={() => props.changeReviewById(index, 'happy')}
                          icon={faSmile}
                          size="1x"
                          color="#46790d"
                        />
                        <FontAwesomeIcon
                          onClick={() =>
                            props.changeReviewById(index, 'unhappy')
                          }
                          className="ml-2"
                          icon={faFrown}
                          size="1x"
                          color="red"
                        />
                      </div>
                    ) : workOrder.review === 'happy' ? (
                      <FontAwesomeIcon
                        icon={faSmile}
                        size="1x"
                        color="#46790d"
                      />
                    ) : (
                      <FontAwesomeIcon icon={faFrown} size="1x" color="red" />
                    )}
                  </td>
                  <td>
                    <button
                      className="btn review-color text-light"
                      onClick={() => changeReview(workOrder._id)}
                    >
                      Change
                    </button>
                  </td>
                </tr>
              ))
            : ''}
        </tbody>
      </Table>
    </div>
  );
};

export default OrderReviewTableComponent;
