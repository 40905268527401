import { history } from './history.service';
const axios = require('axios');

export const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers['authorization'] = token;
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (res) {
    return Promise.resolve(res);
    // return res;
  },

  function (error) {
    // Do something with request error
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      history.go('/admin/login');
      return Promise.reject(error);
    }
  }
);
