import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import LoaderComponent from '../components/route-loader.component';
import { getQueryStringValue } from '../components/query-params.component';
import unsubscribeService from '../services/unsubscribe.service';

const UnsubscribeEmail = withRouter(({ history }) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const UnsubscribeEmail = async () => {
      try {
        const token = getQueryStringValue('token' || '');
        setLoading(true);
        if (token) {
          const unsubscribe = await unsubscribeService.Unsubscribe(token);
          setLoading(false);
        } else {
        }
      } catch (err) {
        setLoading(false);
      }
    };

    UnsubscribeEmail();
  }, []);

  return isLoading ? (
    <LoaderComponent />
  ) : (
    <div className="review-container mt-5">
      <div className="logo mt-5">
        <img src="./newlogo.png" alt="logo" />
      </div>
      <div className="unsubscribe-container">
        <div className="text-center">
          <p>
            You have successfully unsubscribed getting survey emails from
            Central Tub Refinishing, Inc.
          </p>
        </div>
      </div>
      <div>
        <p class="font-weight-bold mt-5">Refinishing Services You Can Trust!</p>
      </div>
    </div>
  );
});

export default UnsubscribeEmail;
