import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useForm from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSmile,
  faFrown,
  faEnvelopeSquare,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';

import { getQueryStringValue } from '../components/query-params.component';
import ReviewService from '../services/review.service';
import OrderReviewTableComponent from '../components/order-review-table.component';
import OrderReviewFeedbackComponent from '../components/order-review-feedback.component';
import OrderReviewFooterComponent from '../components/order-review-footer.component';
import OrderReviewSelectComponent from '../components/order-review-select';

const OrderReview = withRouter(({ history }) => {
  const token = getQueryStringValue('token' || '');
  const reviewValue = getQueryStringValue('review' || '');
  const orderReviewId = getQueryStringValue('orderReviewId' || '');
  const customerEmail = getQueryStringValue('email' || '');
  useEffect(() => {
    const getReviewById = async () => {
      const response = await ReviewService.getReviewByIdGroupByCustomerId(
        orderReviewId
      );
      const orderReviewFormatedData = response.data.reviews.map(
        (reviewObject) => {
          return {
            ...reviewObject,
            review: reviewValue,
            unitNumber: (
              reviewObject.workOrderLines.find(
                (workOrderLine) =>
                  workOrderLine.Inventory &&
                  workOrderLine.Inventory.Name === 'Unit Number'
              ) || {}
            ).Description,
          };
        }
      );
      setOrderReview(orderReviewFormatedData);
    };

    getReviewById();
  }, []);
  const { handleSubmit, errors } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [showReviewSelect, setShowReviewSelect] = useState(false);
  const [review, setReview] = useState(reviewValue);
  const [isNegativeReview, setIsNegativeReview] = useState(reviewValue);
  const [orderReview, setOrderReview] = useState();
  const [selectedReview, setSelectedReview] = useState();

  // const checkReview = token => {};

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (token && review) {
        const negativeReview = orderReview.find(
          (review) => review.review === 'unhappy'
        );

        negativeReview
          ? setIsNegativeReview('unhappy')
          : setIsNegativeReview('happy');

        const response = await ReviewService.postReviews({
          orderReviews: orderReview,
          comment: data.review,
          review,
          token,
          email: customerEmail,
        });
        setLoading(false);
        setSubmitted(true);
      } else {
        setLoading(false);
        toast.error('Your url is malfunctioned', { autoClose: 3000 });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message, {
        autoClose: 3000,
      });
    }
  };

  const changeReview = (reviewVal) => {
    setReview(reviewVal);
    setOrderReview(
      orderReview.map((reviewObject, index) => {
        return {
          ...reviewObject,
          review: reviewVal,
        };
      })
    );
    setShowReviewSelect(false);
  };

  const changeReviewById = (indexReview, review) => {
    setOrderReview(
      orderReview.map((reviewObject, index) => {
        if (indexReview === index) {
          return {
            ...reviewObject,
            review,
          };
        }
        return reviewObject;
      })
    );
    setSelectedReview(false);
  };

  return (
    <div className="review-container mt-3">
      <div className="logo">
        <img src="./newlogo.png" alt="logo" />
      </div>
      {!submitted ? (
        <>
          <div className="customer-review d-flex flex-column">
            {!showReviewSelect ? (
              <div className="pb-3 mb-3">
                {review === 'happy' ? (
                  <FontAwesomeIcon icon={faSmile} size="4x" color="#46790d" />
                ) : (
                  <FontAwesomeIcon icon={faFrown} size="4x" color="red" />
                )}
              </div>
            ) : (
              ''
            )}
            {showReviewSelect ? (
              <OrderReviewSelectComponent setReview={changeReview} />
            ) : (
              ''
            )}
            <div>
              <h5>You are almost done. Just hit the submit button.</h5>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="submit-btn bg-secondary border-radius w-25 m-2 text-light"
                  onClick={() => setShowJobDetails(!showJobDetails)}
                >
                  {showJobDetails ? 'Hide Job Details' : 'Show Job Details'}
                </button>
                <button
                  type="button"
                  className="submit-btn review-color border-radius w-25 m-2 text-light"
                  onClick={() => setShowReviewSelect(!showReviewSelect)}
                >
                  Change Review
                </button>
              </div>

              {showJobDetails ? (
                <OrderReviewTableComponent
                  orderReview={orderReview}
                  review={review}
                  changeReviewById={changeReviewById}
                  setSelectedReview={setSelectedReview}
                  selectedReview={selectedReview}
                />
              ) : (
                ''
              )}
              <p>
                Would you like to tell us more about your experience? Have at
                it. We love to hear what our customers have to say!
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <textarea
                type="text"
                className="form-control review-textarea"
                placeholder="Your comments ( Optional )"
                name="review"
                rows="2"
              />
              {errors.review ? (
                <small>Please enter your valuable review</small>
              ) : null}
            </div>
            <div className="form-group mt-2 mb-2">
              <button
                type="submit"
                className="submit-btn bg-blue border-radius w-25"
                value="Login"
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm overview-submit-spinner"></span>
                ) : (
                  ''
                )}
                Submit
              </button>
            </div>
          </form>
          <div className="ml-4 mb-4">
            <OrderReviewFooterComponent />
          </div>
          <div>
            <p className="font-weight-bold mb-3">
              Refinishing Services You Can Trust!
            </p>
          </div>
        </>
      ) : (
        <div>
          <h4 className="my-5 font-weight-bold">Thank you for your feedback</h4>

          <OrderReviewFeedbackComponent feedback={isNegativeReview} />
          <div className="mb-2 ">
            <div className="text-left">
              <p className="mb-5">Customer Care Team</p>
              <p>Need to speak with us right away? Here's how:</p>
              <div className="py-1">
                <FontAwesomeIcon icon={faEnvelopeSquare} size="1x" />
                <span className="pl-2">
                  customercare@central-refinishing.com
                </span>
              </div>
              <div className="py-1">
                <FontAwesomeIcon icon={faPhoneAlt} size="1x" />
                <span className="pl-2">(609) 496-9436</span>
              </div>
            </div>
          </div>
          <div>
            <h6 className="font-weight-bold mb-3 mt-5">
              Refinishing Services You Can Trust!
            </h6>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
});

export default OrderReview;
