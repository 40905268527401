import React from 'react';

const OrderReviewFeedbackComponent = (props) => {
  return (
    <div className="text-left my-3">
      {props.feedback === 'happy' ? (
        <p className="mt-5">
          We are thrilled that you liked our services. Our team is continuously
          working to achieve total customer satisfaction, and positive reviews
          like yours shows that we are on the right track.
        </p>
      ) : (
        <p className="mt-5">
          We’re sorry you weren’t happy with our services this time around. A
          team member will be in contact with you soon to discuss how we can
          make things right for you.
        </p>
      )}
      <p>We sincerely appreciate your business.</p>
    </div>
  );
};

export default OrderReviewFeedbackComponent;
