import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faFrown } from '@fortawesome/free-solid-svg-icons';

const OrderReviewSelectComponent = (props) => {
  return (
    <div className="d-flex justify-content-center mb-4">
      <div className="review-cursor">
        <FontAwesomeIcon
          icon={faSmile}
          size="4x"
          color="#46790d"
          onClick={() => props.setReview('happy')}
        />
      </div>
      <div className="pl-5 review-cursor">
        <FontAwesomeIcon
          icon={faFrown}
          size="4x"
          color="red"
          onClick={() => props.setReview('unhappy')}
        />
      </div>
    </div>
  );
};

export default OrderReviewSelectComponent;
