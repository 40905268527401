import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ErrorContext from './contexts/error.context';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ErrorBoundary } from 'react-error-boundary';
import OurFallbackComponent from './components/fallback.component';
import LoaderComponent from './components/route-loader.component';
import ResetPasswordPage from './pages/reset-password.page';
import NewPasswordPage from './pages/change-password';
import OrderReview from './pages/order-review.page';
import SubmittedReviewPage from './pages/submitted-review.page';
import UnsubscribeEmail from './pages/unsubscribe-email.page';
const HomePage = lazy(() => import('./pages/home-page'));
const Calendar = lazy(() => import('./pages/calendar'));
const Search = lazy(() => import('./pages/search'));
const LoginPage = lazy(() => import('./pages/login-page'));
const ProtectedRoute = lazy(() => import('./protected.route'));
const ProtectedReviewRoute = lazy(() => import('./protected-review.route'));
function App() {
  const errorMessages = [
    { code: '401', message: 'Unauthorized. Please try again.' },
    {
      code: '400',
      message: 'Bad request. Internal communication error. Please try again.',
    },
    { code: '404', message: 'Not found error. Please try again.' },
    {
      code: '500',
      message: 'Service Bridge communication error. Please try again',
    },
    {
      code: '000',
      message: 'Hmm, something went wrong. Please contact support.',
    },
  ];

  const errorhandler = (error, errorInfo) => {};
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<LoaderComponent />}>
          <ErrorContext.Provider value={{ errorMessages }}>
            <ErrorBoundary
              FallbackComponent={OurFallbackComponent}
              onError={errorhandler}
            >
              <Switch>
                <ProtectedRoute path="/" exact component={HomePage} />
                <ProtectedRoute path="/calendar" exact component={Calendar} />
                <ProtectedRoute path="/search" exact component={Search} />
                <Route path="/login">
                  <LoginPage></LoginPage>
                </Route>
                <Route path="/reset-password">
                  <ResetPasswordPage />
                </Route>
                <Route path="/new-password">
                  <NewPasswordPage />
                </Route>
                <ProtectedReviewRoute
                  path="/review"
                  exact
                  component={OrderReview}
                />
                <Route path="/submitted-review">
                  <SubmittedReviewPage />
                </Route>
                <Route path="/unsubscribe">
                  <UnsubscribeEmail />
                </Route>
              </Switch>
            </ErrorBoundary>
          </ErrorContext.Provider>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
