import React from 'react';
import { useHistory } from 'react-router-dom';
import OrderReviewFooterComponent from '../components/order-review-footer.component';

const SubmittedReviewPage = () => {
  const history = useHistory();
  return (
    <div className="review-container submitted-container">
      <div className="logo">
        <img src="./newlogo.png" alt="logo" />
      </div>
      <h4 className="my-5 font-weight-bold">Review Already Submitted</h4>

      <p className="mb-5">
        A review has already been submitted by{' '}
        {history.location.state.customerEmail}.
      </p>

      <div className="mb-2">
        <OrderReviewFooterComponent />
      </div>
      <div>
        <h6 className="font-weight-bold mb-3 mt-5">
          Refinishing Services You Can Trust!
        </h6>
      </div>
    </div>
  );
};

export default SubmittedReviewPage;
